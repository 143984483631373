<template>
	<div>
		<hr class="thicker-radius" />
		<div class="getSMS">
			<h2 class="warn">{{ languageStrings.phoneNotVerified }}</h2>
			<button class="btn" type="button" :title="languageStrings.getVerificationCode" @click="requestVerificationCode()">
				{{ languageStrings.sendConfirmationCodeTo }} {{ playerState?.phoneNumber }}
			</button>
			<small style="text-align: center">{{ languageStrings.textDisclaimer }}</small>
			<div v-if="codeSent" class="flex-horiz">
				<label for="confirmation">{{ languageStrings.confirmationCodeText }}</label>
				<input
					type="text"
					id="confirmation"
					name="confirmation"
					maxlength="6"
					pattern="\d{6}"
					v-model="confirmationCode"
					:placeholder="languageStrings.enterConfirmationCode"
					autocomplete="off"
					onfocus="this.value=''"
				/>
				<button class="btn" type="button" :title="languageStrings.verifyAccount" @click="verify()">{{ languageStrings.verifyCode }}</button>
			</div>
		</div>
	</div>
</template>

<script>
import router from "@/router";
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "VerifyUserPhone",
	props: {
		playerState: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			codeSent: false,
			confirmationCode: "",
		};
	},
	mounted() {
		this.requestVerificationCode();
	},
	methods: {
		async requestVerificationCode() {
			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let requestUrl = new URL("/api/v1/user/verification/code", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					fetchStatus.message = fetchStatus.code === 404 ? this.languageErrorStrings.phoneNotFound : fetchStatus.message;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				switch (dataJson?.status) {
					case "DeniedByAccountState":
						this.status.ok = false;
						this.status.message = this.languageErrorStrings.smsCodeNotSent;
						this.eventBus.emit("updateStatus", this.status);
						return;
					case "FailedToSend":
						this.status.ok = false;
						this.status.message = `${this.languageErrorStrings.unableToSendSMS} ${this.playerState.phoneNumber}.`;
						this.eventBus.emit("updateStatus", this.status);
						return;
					case "Success":
						this.status.message = `${this.languageErrorStrings.smsCodeSentTo} ${this.playerState.phoneNumber}.`;
						this.codeSent = true;
						this.eventBus.emit("updateStatus", this.status);
				}
			} catch (e) {
				console.error(e);
			}
		},
		async verify() {
			if (!this.confirmationCode) {
				this.status.message = this.languageErrorStrings.provideCode;
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
				return false;
			}

			let body = {
				PhoneNumber: this.playerState?.phoneNumber.toString(),
				Code: this.confirmationCode,
			};

			let requestUrl = new URL("/api/v1/user/verification", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				if (fetchStatus.ok) this.status = sharedScripts.checkSuccessErrors(dataJson.status, this.languageErrorStrings);

				if (this.status.message === "Success") {
					this.status.message = this.languageErrorStrings.accountVerified;
					let STATE = this.playerState;
					STATE.confirmationCode = this.confirmationCode;
					STATE.isUserVerified = true;
					this.eventBus.emit("updatePlayerState", STATE);
					// router.push("/");
				}
				this.eventBus.emit("updateStatus", this.status);
			} catch (e) {
				console.error(e);
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2,
h3 {
	text-align: center;
}

.getSMS {
	display: flex;
	flex-direction: column;
	width: 75%;
	margin: auto;
	/* background-color: #24272c;
	padding: 30px;
	border-radius: 0 0 8px 8px;
	box-shadow: 2px 3px 8px rgb(0 0 0 / 75%), inset 4px 3px 10px 4px rgb(42 63 88 / 30%), inset -2px -2px 10px 2px rgb(0 0 0 / 30%); */
}

.getSMS form {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	text-align: center;
}

.flex-horiz {
	margin: 15px auto 0;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-items: center;
	align-content: center;
	text-align: center;
}

.flex-horiz > * {
	flex: 1 0 auto;
}

@media (min-width: 768px) {
	.getSMS {
		width: 50%;
	}
}
</style>
